<template>
  <TTView>
    <VRow align="center">
      <VCol cols="auto">
        <TTBreadcrumbs
          class="pa-0"
          :items="breadcrums"
        />
      </VCol>
      <VSpacer />
      <VCol cols="auto">
        <TTBtn
          color="primary"
          :disabled="loading"
          :to="{ name : Names.R_ACCOUNT_V2_COMPANY_TEAM_EDIT }"
        >
          Редактировать команду
        </TTBtn>
      </VCol>
      <VCol cols="12">
        <span class="text-h4">{{ team.name }}</span>
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Отображаемое название
      </VCol>
      <VCol cols="9">
        {{ team.displayName }}
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Тип
      </VCol>
      <VCol cols="9">
        {{ team.teamType }}
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Внешний идентификатор
      </VCol>
      <VCol cols="9">
        {{ team.externalId }}
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Дочерняя команда
      </VCol>
      <VCol cols="9">
        {{ isChild ? "Да" : "Нет" }}
      </VCol>
    </VRow>
    <VRow
      v-if="isChild"
      class="text-body-2"
    >
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Родительская команда
      </VCol>
      <VCol cols="9">
        {{ team.parent.displayName }}
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VDivider />
      </VCol>
    </VRow>
    <RouterView />
  </TTView>
</template>

<script>
export default {
  name: 'TeamView',
  inject: ['Names'],
  props: {
    accountId: { type: String, required: true },
    companyId: { type: String, required: true },
    teamId: { type: String, required: true },
  },
  data() {
    return {
      account: {},
      company: {},
      team: {},
      teamsFromPath: [],
    };
  },
  computed: {
    isChild() {
      return !!this.team.parent;
    },
    companyName() {
      return this.company?.name ? this.company.name : `Компания: ${this.companyId}`;
    },
    breadcrums() {
      const breadcrums = [
        { to: { name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW }, exact: true, text: this.account.name },
        { to: { name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW }, exact: true, text: this.companyName },
        { to: { name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW_TEAM_LIST }, exact: true, text: 'Команды' },
      ];

      if (this.isChild) {
        this.teamsFromPath.forEach((el) => {
          breadcrums.push({
            to: {
              name: this.Names.R_ACCOUNT_V2_COMPANY_TEAM_VIEW_STAFF_POSITION,
              params: {
                teamId: el.id,
              },
            },
            exact: true,
            text: el.displayName,
          });
        });
      } else {
        breadcrums.push({
          to: {
            name: this.Names.R_ACCOUNT_V2_COMPANY_TEAM_VIEW_STAFF_POSITION,
            params: {
              teamId: this.team.id,
            },
          },
          exact: true,
          text: this.team.displayName,
        });
      }

      return breadcrums;
    },
  },
  watch: {
    teamId: {
      handler: 'fetch', immediate: true,
    },
  },
  methods: {
    async fetch() {
      try {
        this.loading = true;
        const accountsApp = new this.$di.ddd.Accounts();
        const orgstructureApp = new this.$di.ddd.Orgstructure();

        const [[account], [company], [team]] = await Promise.all([
          await accountsApp.services.account.getAccount({ id: this.accountId }),
          await accountsApp.services.account.getCompany({ id: this.companyId }),
          await orgstructureApp.services.team.getTeam({ id: this.teamId, companyId: this.companyId }),
        ]);

        const teamsFromPath = await Promise.all(team.path.map(async (teamId) => {
          const [response] = await orgstructureApp.services.team.getTeam({ id: teamId, companyId: this.companyId });
          return response;
        }));

        this.teamsFromPath = [...teamsFromPath];
        this.account = { ...account };
        this.company = { ...company };
        this.team = { ...team };
      } catch (err) {
        //
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
